import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: () => import('../views/Teacher.vue')
  },
  {
    path: '/check',
    name: 'Check',
    meta: {
      title: '江苏地区非遗技艺评价查询系统'
    },
    component: () => import('../views/CheckBook.vue')
  },
  {
    path: '/appraisal',
    name: 'AppraisalBook',
    component: () => import('../views/AppraisalBook.vue')
  },
  {
    path: '/uploadOk',
    name: 'UploadOk',
    component: () => import('../views/UploadOk.vue')
  },
  {
    path: '/sign',
    name: 'sign',
    component: () => import('../views/SignScreen.vue')
  },
  {
    path: '/signShow',
    name: 'signShow',
    component: () => import('../views/SignScreenShow.vue')
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
